// http://ionicframework.com/docs/theming/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "./theme/custom-ion-colors.scss";
// Add base app styles
@import "./theme/app-defaults.scss";
// Add base shell styles
@import "./theme/shell-defaults.scss";


@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe:ital@0;1&family=Atma:wght@300;400;500;600;700&family=Atomic+Age&family=Barrio&family=Bilbo+Swash+Caps&family=Black+Ops+One&family=Bruno+Ace&family=Bungee+Shade&family=Delius+Swash+Caps&family=DynaPuff:wght@400..700&family=Eater&family=Gugi&family=Jolly+Lodger&family=Love+Light&family=Marck+Script&family=Miss+Fajardose&family=Mystery+Quest&family=Nosifer&family=Orbitron:wght@400..900&family=Playwrite+CO:wght@100..400&family=Rampart+One&family=Rochester&family=Rubik+Iso&family=Rubik+Marker+Hatch&family=Rubik+Wet+Paint&family=Rye&family=Saira:ital,wght@0,100..900;1,100..900&family=Silkscreen:wght@400;700&family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap');


/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
