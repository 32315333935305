/*
 * Dark Colors
 * ------------------
 */

body.dark {
  --app-background-shade: #2e2e2e;
  --ion-color-primary: #d6d6d8;
  --ion-color-primary-rgb: 11, 13, 33;
  --ion-color-primary-shade: #bbbbbb;
  --ion-color-primary-tint: #010101;

  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * ------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #0f0e2b;
  --ion-color-primary-rgb: rgb(3, 1, 34);
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000000;
  --ion-color-primary-tint: #22264c;

  /** secondary **/
  --ion-color-secondary: #ff38ae;
  --ion-color-secondary-rgb: 255, 56, 174;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #b11d74;
  --ion-color-secondary-tint: #ff67c0;

  /** secondary **/
  // --ion-color-secondary: #FF0062;
  // --ion-color-secondary-rgb: 255,0,98;
  // --ion-color-secondary-contrast: #ffffff;
  // --ion-color-secondary-contrast-rgb: 255,255,255;
  // --ion-color-secondary-shade: #e00056;
  // --ion-color-secondary-tint: #ff1a72;

  /** tertiary **/
  --ion-color-tertiary: #00afff;
  --ion-color-tertiary-rgb: 0, 175, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #009ae0;
  --ion-color-tertiary-tint: #1ab7ff;

  /** success **/
  --ion-color-success: #20dc6a;
  --ion-color-success-rgb: 32, 220, 106;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #1cc25d;
  --ion-color-success-tint: #36e079;

  /** success secondary **/
  --ion-color-success_secondary: #28a745;
  --ion-color-success_secondary-rgb: 40, 167, 69;
  --ion-color-success_secondary-contrast: #ffffff;
  --ion-color-success_secondary-contrast-rgb: 255, 255, 255;
  --ion-color-success_secondary-shade: #218838;
  --ion-color-success_secondary-tint: #31b950;

  /** warning **/
  --ion-color-warning: #ffed11;
  --ion-color-warning-rgb: 255, 237, 17;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0d10f;
  --ion-color-warning-tint: #ffef29;

  /** danger **/
  --ion-color-danger: #f4344f;
  --ion-color-danger-rgb: 244, 52, 79;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d72e46;
  --ion-color-danger-tint: #f54861;

  /** lightest **/
  --ion-color-lightest: #ffffff;
  --ion-color-lightest-rgb: 255, 255, 255;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-shade-rgb: 215, 216, 218;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-tint-rgb: 245, 246, 249;

  /** medium **/
  --ion-color-medium: #76777b;
  --ion-color-medium-rgb: 118, 119, 123;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #595a5d;
  --ion-color-medium-shade-rgb: 89, 90 93;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-medium-tint-rgb: 162, 164, 171;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-shade-rgb: 30, 32, 35;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-tint-rgb: 56, 58, 62;

  /** darkest **/
  --ion-color-darkest: #000000;
  --ion-color-darkest-rgb: 0, 0, 0;
}

:root {
  /* Set the font family of the entire app */
  --ion-font-family: "Rubik", sans-serif;

  --app-background: #ffffff;
  --app-background-shade: var(--ion-background-color-step-50, #f5f6fb);
  --app-background-alt: var(--ion-color-primary);
  --app-background-alt-shade: var(--ion-color-primary-shade);
  --app-background-dark: #060817;

  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;

  /* Barhelper variables */
  --page-intro-background: #fff;
  --page-intro-margin: 0 0 10px 0;
  --page-intro-box-shadow: none;
  --page-intro-border-radius: 0;
  --list-header-padding: 0 12px;
}

html.ios {
  --app-header-height: 44px;
}

html.md {
  --app-header-height: 56px;
}

/* -------- custom common css --------- */

/* Custom colors */
.ion-color-success_secondary {
  --ion-color-base: var(--ion-color-success_secondary);
  --ion-color-base-rgb: var(--ion-color-success_secondary-rgb);
  --ion-color-contrast: var(--ion-color-success_secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success_secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-success_secondary-shade);
  --ion-color-tint: var(--ion-color-success_secondary-tint);
}

/* ion-card styles */
ion-card {
  border-radius: 10px;
  padding: 2px;
}

ion-card:focus {
  outline: none;
}

ion-card.card-active {
  padding: 0;
  border-style: solid;
  border-width: 2px;
  border-color: var(--ion-color-success);
}

ion-card.card-disabled {
  opacity: 0.45;
}

ion-card-header {
  border-radius: 10px 10px 0px 0px;
}

ion-card-content {
  border-radius: 0px 0px 10px 10px;
}

.card-content-md h1 {
  font-size: 20px;
}

/* ion list header */
ion-list-header ion-label {
  font-weight: 500;
}

/* Grid list */
.grid-list {
  ion-row {
    margin-left: -10px;
    margin-right: -10px;
  }
  ion-col {
    padding-bottom: 0;
  }
}

/* Page intro */
.page-intro {
  clear: both;
  padding: var(--app-fair-margin);
  margin: var(--page-intro-margin);
  background: var(--page-intro-background);
  border-radius: var(--page-intro-border-radius);
  box-shadow: var(--page-intro-box-shadow);
  .page-intro-title {
    margin-top: 0;
  }
  ion-thumbnail {
    float: left;
    margin-right: 10px;
  }
}

/* Page statistics */
.page-stats {
  clear: both;
  padding-left: var(--app-fair-margin);
  padding-right: var(--app-fair-margin);
  margin: var(--page-intro-margin);
  background: var(--page-intro-background);
  border-radius: var(--page-intro-border-radius);
  box-shadow: var(--page-intro-box-shadow);
  li ion-icon {
    margin-right: 5px !important;
  }
  @media (max-width: 768px) {
    .item-list.item-list-inline {
      flex-flow: column !important;
    }
  }
}

@media (max-width: 768px) {
  .page-stats {
    padding: var(--app-fair-margin);
  }
}

/* List items */
.item-list {
  margin: 0 -6px;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    div {
      display: flex;
      flex-shrink: 0;
      align-items: center;
    }
    div + div {
      padding-left: 5px;
    }
  }
  li ion-icon {
    margin-right: 5px;
  }
}
.item-list.item-list-inline {
  display: flex;
  flex-wrap: wrap;
}
.list-inline-note {
  font-size: 12px;
  padding: 0;
  list-style: none;
}

.item-list-small {
  font-size: 12px;
}

/* No background */
.no-background {
  --background-color: rgba(0, 0, 0, 0) !important;
}

/* Image cropper */
.cropper img {
  max-height: 200px !important;
}

b,
strong,
h1,
h2,
h3 {
  font-weight: 500 !important;
}

.w-100 {
  width: 100%;
}

/* Text-color */
.color-medium {
  color: var(--ion-color-medium);
}

.color-danger {
  color: var(--ion-color-danger);
}

/* Margins */
.ion-mb-0 {
  margin-bottom: 0 !important;
}
