@import "./mixins/ion-color";

// Add custom colors to use with [color] property
// (see: https://forum.ionicframework.com/t/adding-custom-colours-to-use-with-color-property/136919)
// GLOBAL COLORS:
//    You can define new Ionic colors here (inside :root{ ... }) to be available within the entire app
// LOCAL COLORS:
//    You can also define new Ionic colors in each page (inside :host{ ... }) to be available just in that page
//    (We have an example of local custom Ionic colors in the deals pages)
:root {
  @include ion-color('facebook') {
    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59,89,152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255,255,255;
    --ion-color-facebook-shade: #344e86;
    --ion-color-facebook-tint: #4f6aa2;
  }

  @include ion-color('google') {
    --ion-color-google: #cc181e;
    --ion-color-google-rgb: 204,24,30;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255,255,255;
    --ion-color-google-shade: #b4151a;
    --ion-color-google-tint: #d12f35;
  }

  @include ion-color('twitter') {
    --ion-color-twitter: #00aced;
    --ion-color-twitter-rgb: 0,172,237;
    --ion-color-twitter-contrast: #ffffff;
    --ion-color-twitter-contrast-rgb: 255,255,255;
    --ion-color-twitter-shade: #0097d1;
    --ion-color-twitter-tint: #1ab4ef;
  }
}
